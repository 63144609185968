import React, { cloneElement, useState, useEffect } from 'react'
import styled from 'styled-components'

const Slots = styled.div`
  max-height: 100px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e7e7e7;
  overflow: hidden;
`

const Slot = styled.div<{ to: number; noTransition: boolean }>`
  position: relative;
  transform: translate(0, -${props => props.to}px);
  transition: ${props => !props.noTransition && `transform 1s ease-out`};
`

const Emoji = styled.div`
  display: flex;
  align-items: center;

  & span {
    height: 100px;
    font-size: 84px;
  }
`

const emojis = ['🤔', '😀', '🤯', '🧐', '🚀', '😎', '😮', '👺', '🤡']

function Emojis() {
  return (
    <>
      {emojis.map(emoji => (
        <Emoji key={emoji}>
          <span role="img">{emoji}</span>
        </Emoji>
      ))}
    </>
  )
}

function SlotView() {
  const [rolling, setRolling] = useState(false)
  const [toProp, setToProp] = useState(0)
  const [noTransition, setNoTransition] = useState(false)

  useEffect(() => {
    if (noTransition) {
      setToProp(a => a - emojis.length * 200)
      setNoTransition(false)
      setTimeout(() => {
        setRolling(false)
      }, 800)
    }
  }, [noTransition])

  function spin() {
    const random = Math.floor(Math.random() * emojis.length)
    const at = random * 100 + emojis.length * 200
    setRolling(true)
    setToProp(at)

    setTimeout(() => {
      setNoTransition(true)
    }, 500)
  }

  return (
    <>
      <Slots>
        <div style={{ display: 'flex' }}>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
          <Slot to={toProp} noTransition={noTransition}>
            <Emojis />
            {rolling && (
              <>
                <Emojis />
                <Emojis />
              </>
            )}
          </Slot>
        </div>
      </Slots>
      <button onClick={spin} disabled={rolling}>
        Spin
      </button>
    </>
  )
}

export default function MegaEmoji() {
  return (
    <div>
      <h4>Mega Emoji</h4>
      <SlotView />
    </div>
  )
}
