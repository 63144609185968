import React from 'react'
import { Route } from 'react-router'
import MegaEmoji from 'pages/MegaEmoji'

export default function App() {
  return (
    <div>
      <h2>Slotiky</h2>
      <Route path="/mega-emoji" component={MegaEmoji} />
    </div>
  )
}
